import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BlogCard = _resolveComponent("BlogCard")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createBlock(_component_v_container, { class: "mb-6" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_row, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.posts, (post) => {
            return (_openBlock(), _createBlock(_component_v_col, {
              key: post.slug,
              cols: "4"
            }, {
              default: _withCtx(() => [
                _createVNode(_component_BlogCard, {
                  class: "ma-5",
                  slug: post.slug,
                  image: _ctx.baseUrl + post.image.url,
                  date: new Date(post.publishedAt).toISOString().split('T')[0],
                  title: post.title,
                  summary: post.summary,
                  authorImage: _ctx.baseUrl + post.author.image.url,
                  authorName: post.author.name
                }, null, 8, ["slug", "image", "date", "title", "summary", "authorImage", "authorName"])
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}