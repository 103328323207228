import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "blogDetail" }
const _hoisted_2 = { class: "blogDetail__head" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "blogDetail__body_img" }
const _hoisted_5 = ["src"]
const _hoisted_6 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", null, _toDisplayString(_ctx.post?.title), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.baseUrl + _ctx.post?.author.image.url,
        class: "blogDetail__profileImg"
      }, null, 8, _hoisted_3),
      _createElementVNode("span", null, _toDisplayString(_ctx.post?.author.name), 1),
      _createElementVNode("span", null, _toDisplayString(_ctx.formatDate(_ctx.post?.publishedAt)), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("img", {
        src: _ctx.baseUrl + _ctx.post?.image.url
      }, null, 8, _hoisted_5),
      _createElementVNode("div", {
        class: "blogDetail__body",
        innerHTML: _ctx.post?.body
      }, null, 8, _hoisted_6)
    ])
  ]))
}