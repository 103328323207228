import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_tooltip = _resolveComponent("v-tooltip")!
  const _component_v_switch = _resolveComponent("v-switch")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_v_app_bar, null, {
      default: _withCtx(() => [
        _createVNode(_component_v_app_bar_nav_icon, null, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'bars'] })
          ]),
          _: 1
        }),
        _createVNode(_component_v_toolbar_title, {
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.push('/'))),
          class: "mouse"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" Eduardo's Blog ")
          ]),
          _: 1
        }),
        _createVNode(_component_v_spacer),
        ($setup.state.search.searching)
          ? (_openBlock(), _createBlock(_component_v_text_field, {
              key: 0,
              label: $setup.state.search.loading ? 'Searching...' : 'Search...',
              loading: $setup.state.search.loading,
              disabled: $setup.state.search.loading,
              onFocusout: $setup.toggleSearch,
              flat: "",
              density: "compact",
              "hide-details": ""
            }, null, 8, ["label", "loading", "disabled", "onFocusout"]))
          : _createCommentVNode("", true),
        _createVNode(_component_v_tooltip, { text: "Search posts" }, {
          activator: _withCtx(({ props }) => [
            _createVNode(_component_v_btn, _mergeProps(props, {
              icon: "",
              onClick: $setup.toggleSearch
            }), {
              default: _withCtx(() => [
                _createVNode(_component_font_awesome_icon, { icon: ['fas', 'magnifying-glass'] })
              ]),
              _: 2
            }, 1040, ["onClick"])
          ]),
          _: 1
        }),
        _createElementVNode("div", null, [
          _createVNode(_component_v_switch, {
            modelValue: $setup.isDarkTheme,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (($setup.isDarkTheme) = $event)),
            onClick: $setup.toggleTheme,
            "false-icon": "fas fa-sun",
            "true-icon": "fas fa-moon",
            "hide-details": "",
            inset: ""
          }, null, 8, ["modelValue", "onClick"])
        ]),
        _createVNode(_component_v_btn, { icon: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'heart'] })
          ]),
          _: 1
        }),
        _createVNode(_component_v_btn, { icon: "" }, {
          default: _withCtx(() => [
            _createVNode(_component_font_awesome_icon, { icon: ['fas', 'ellipsis'] })
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}